import React from "react"
import styled from "styled-components"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const LabeledIcon = ({ label, SvgComponent }) => {
  return (
    <Container>
      <SvgIcon size="lg" hue="ocean" shade="060" SvgComponent={SvgComponent} />
      {label}
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  font-size: 25px;
  color: #223247;
  display: flex;
  align-items: center;

  > span.icon {
    margin-right: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    font-size: 22px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 16px;
    font-weight: 500;
    > span.icon {
      margin-right: 6px;
    }
  }
`

export default LabeledIcon
