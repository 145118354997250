import React, { useState, useEffect, useContext } from "react"
import { getUser } from "../../services/auth"
import AuthContext from "../../context/AuthContext"
import { getAllOrders, getCustomer } from "../../lib/woocommApi"
import EllipsisLoader from "../../zzz/organisms/ellipsisLoader"
import Order from "./Order"

import { BtnSquare } from "../../zzz/atoms/buttons"
import styled from "styled-components"

// Tabs component. Should be refactored at some point
const OrdersList = ({ givenOrderList = [], urlsAvailable = [] }) => {
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState("")
  const [orders, setOrders] = useState(givenOrderList)
  const { loggedInState, performLogout } = useContext(AuthContext)

  // Fetch all orders from WC
  useEffect(() => {
    if (givenOrderList.length > 0) return setLoading(false)

    if (loggedInState === true) {
      const { token } = getUser()
      setLoading(true)
      getAllOrders(token, performLogout)
        .then(async (allOrders) => {
          try {
            // Loop through orders and push to array
            // This helps to avoid accidentally using an error response or something
            const newOrders = []
            for (let i = 0; i < allOrders.length; i += 1) {
              newOrders.push({ ...allOrders[i] })
            }
            setOrders(newOrders)
            setLoading(false)
          } catch (e) {
            setOrders([])
            setLoading(false)
            console.log("Failed to process newOrders: ", e)
          }
        })
        .catch((e) => {
          setOrders([])
          if (e.message) setApiError(e.message)
          else setApiError("Oh no! Fetching orders failed")
        })
    } else {
      setLoading(false)
    }
  }, [])

  // While loading, show loader
  if (loading) return <EllipsisLoader key="other-loader" />

  if (orders.length === 0)
    return (
      <Container>
        <h3>You have no orders.</h3>
        <BtnSquare to="/bed-finder/">Go Shopping</BtnSquare>
      </Container>
    )

  return (
    <>
      {orders.map((order, i) => (
        <div key={order.id}>
          <Order
            orderNumber={order.id}
            orderNumberFormatted={order.order_number}
            orderDate={order.date_created.date}
            {...order}
            items={order.line_items}
            orderTotal={order.total}
            shippingTotal={
              parseFloat(order.shipping_total) + parseFloat(order.shipping_tax)
            }
            discount={
              parseFloat(order.discount_total) + parseFloat(order.discount_tax)
            }
            payment_method_title={order.payment_method_title}
            shippingData={order.shipping_data}
            urlsAvailable={urlsAvailable}
          />
        </div>
      ))}
    </>
  )
}

const Container = styled.div`
  text-align: center;
  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }
`

export default OrdersList
