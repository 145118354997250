import React from "react"
import styled from "styled-components"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Badge = ({ color = "yellow", SvgComponent, label }) => {
  return (
    <Container color={color}>
      {SvgComponent && (
        <SvgIcon SvgComponent={SvgComponent} {...colors[color]} />
      )}
      {label && (
        <span className="label">{`${label.charAt(0).toUpperCase()}${label.slice(
          1
        )}`}</span>
      )}
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  border-radius: 4px;
  padding: 5px;
  overflow: hidden;
  background: ${({ color }) => colors[color].background};
  color: ${({ color }) => colors[color].color};
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 3px;
  span.label {
    padding: 0 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 16px;
  }
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
const colors = {
  blue: { background: "#48B6DD", color: "#FFFFFF", hue: "white", shade: "000" },
  yellow: {
    background: "#FFC82C",
    color: "#223247",
    hue: "midnight",
    shade: "040",
  },
  green: {
    background: "#19CD67",
    color: "#FFFFFF",
    hue: "white",
    shade: "000",
  },
  grey: {
    background: "#DEE3E8",
    color: "#223247",
    hue: "white",
    shade: "000",
  },
  red: {
    background: "#FD3237",
    color: "#FFFFFF",
    hue: "white",
    shade: "000",
  },
}

export default Badge
