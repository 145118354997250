import React from "react"

const IconSecurePayment = ({ fill }) => (
  <svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.72 2.62h12.56v-.95c0-.5-.29-.92-.92-.92H1.64a.92.92 0 00-.92.92v.95zm12.56 2H.72v4.71c0 .5.41.92.92.92H9.5V11H1.22C.5 11 0 10.32 0 9.66V1.28C0 .62.67 0 1.38 0h11.45C13.37 0 14 .59 14 1.28v4.37c-.21-.1-.45-.19-.72-.25v-.78zM13 11.63a.38.38 0 01-.38-.38v-.87c0-.22.17-.39.38-.39.2 0 .38.17.38.39v.87c0 .21-.17.38-.38.38zm3-2.35v3.06c0 .37-.29.66-.64.66h-4.72a.65.65 0 01-.64-.66V9.28c0-.36.29-.65.64-.65h.43v-.66c0-1.09.87-1.97 1.94-1.97 1.06 0 1.92.9 1.92 1.99v.64h.43c.35 0 .64.29.64.65zm-4.29-.65h2.58v-.66A1.3 1.3 0 0013 6.66c-.7 0-1.29.59-1.29 1.3v.67zm3.65.65h-4.72v3.06h4.72V9.28zM4.44 8.4v.2a.3.3 0 01-.3.3H2.3a.3.3 0 01-.3-.3v-.2c0-.17.14-.3.3-.3h1.83c.17 0 .3.13.3.3zm4.87 0v.2a.3.3 0 01-.3.3H5.55a.3.3 0 01-.3-.3v-.2c0-.17.14-.3.3-.3h3.46c.17 0 .3.13.3.3z"
      fill={fill}
    />
  </svg>
)

export default IconSecurePayment
