import React from "react"
import styled from "styled-components"
import { format } from "date-fns"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Title = ({ orderNumber, orderNumberFormatted, orderDate, payDate }) => {
  let orderDateString = orderDate
  let payDateString = payDate

  if (orderDateString && orderDateString.includes(" ")) {
    try {
      orderDateString = Date.parse(orderDateString.split(" ")[0])
      orderDateString = format(new Date(orderDateString), "dd MMM yyyy")
    } catch {
      orderDateString = orderDate
    }
  }
  if (payDateString && payDateString.includes(" ")) {
    try {
      payDateString = Date.parse(payDateString.split(" ")[0])
      payDateString = format(new Date(payDateString), "dd MMM yyyy")
    } catch {
      payDateString = payDate
    }
  }

  return (
    <Container>
      <div className="order-number">
        Order {orderNumberFormatted ? orderNumberFormatted : `#${orderNumber}`}
      </div>
      {orderDate && <div className="date">Ordered {orderDateString}</div>}
      {payDate && <div className="date">Paid {payDateString}</div>}
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 30px;
  margin: 20px 0;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 18px;
    text-align: center;
  }
  div {
    display: flex;
    flex-direction: row;
  }
  div:first-child:not(:last-child) {
    margin-right: 10px;
  }
  div:last-child:not(:first-child) {
    margin-left: 10px;
  }
  div:not(:last-child):not(:first-child) {
    margin: 0 10px;
  }
  div:not(:last-child):after {
    display: block;
    content: "";
    border: 2px solid #48b6dd;
    margin-left: 20px;
  }
  .order-number {
    font-weight: bold;
  }
  .date {
  }
`

export default Title
