import React from "react"
import styled from "styled-components"
import { useState } from "react"
import Item from "./Item"
import Badge from "./Badge"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"
import HourglassStart from "../../../zzz/atoms/icons/files/hourglass-start.jsx"
import Check from "../../../zzz/atoms/icons/files/check.jsx"
import Cross from "../../../zzz/atoms/icons/files/cross.jsx"
import Warn from "../../../zzz/atoms/icons/files/warn.jsx"
import ChevronDown from "../../../zzz/atoms/icons/files/chevron-down.jsx"
import ArrowRight from "../../../zzz/atoms/icons/files/arrow-right.jsx"
import ArrowLeft from "../../../zzz/atoms/icons/files/arrow-left.jsx"
import LabeledIcon from "./LabeledIcon"
import LocationPin from "../../../zzz/atoms/icons/files/location-pin.jsx"
import Delivery from "../../../zzz/atoms/icons/files/delivery.jsx"
import SecurePayment from "../../../zzz/atoms/icons/files/secure-payment.jsx"
import AddressCard from "../../../zzz/atoms/icons/files/address-card.jsx"
import { formatMoney } from "../../../lib/util"
import { navigate } from "gatsby"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const OrderCard = ({
  items = [],
  orderNumber,
  orderNumberFormatted,
  status,
  billing = {},
  payment_method,
  shipping_lines = [],
  line_items,
  shipping_total,
  discount = 0,
  total,
  shipping_data,
  urlsAvailable = [],
}) => {
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(0)
  const payOnDelivery = payment_method == "cod" || payment_method == "ccondel"

  // Difference between these two is eg. a "refunded" or "cancelled" order does not need payment
  // but it also was not paid

  // eg. to show "Pay Now" button
  const needsPayment =
    status == "on-hold" ||
    status == "pending" ||
    (status == "processing" && payment_method == "bacs") ||
    status == "failed"
  // eg. to show "Paid" label
  const wasPaid =
    status == "completed" || (status == "processed" && !payOnDelivery)

  let summary_title = "Order Payment is being Processed"
  let summary_badge_color = "blue"
  let summary_badge_icon = HourglassStart

  if (status == "pending") {
    summary_title = "Order is Pending Payment"
  } else if (status == "completed") {
    summary_title = "Order has been Completed"
    summary_badge_color = "green"
    summary_badge_icon = Check
  } else if (status == "on-hold") {
    summary_badge_color = "yellow"
    summary_title = "Order is on Hold"
  } else if (status == "cancelled") {
    summary_title = "Order has been Cancelled"
    summary_badge_color = "red"
    summary_badge_icon = Cross
  } else if (status == "refunded") {
    summary_title = "Order Items have been Refunded"
    summary_badge_icon = ArrowLeft
  } else if (status == "failed") {
    summary_title = "Order Payment has Failed"
    summary_badge_color = "red"
    summary_badge_icon = Warn
  } // else if (status == "processing") handled by default

  const shipping_method = shipping_data?.method_title || null
  let shipping_branch = null
  let shipping_message = null
  const shipping_meta = shipping_data?.meta_data || []
  for (let index = 0; index < shipping_meta?.length; index++) {
    const meta = shipping_meta[index]
    if (meta.key == "branch") {
      shipping_branch = meta.value
    }
    if (meta.key == "message") {
      shipping_message = meta.value
    }
    if (shipping_branch && shipping_message) {
      break
    }
  }

  return (
    <Container>
      <Summary>
        <Title>
          <h4>{summary_title}</h4>
          <div className="badges">
            {!payOnDelivery && (
              <Badge
                label={status}
                color={summary_badge_color}
                SvgComponent={summary_badge_icon}
              />
            )}
            {wasPaid && (
              <Badge label={"Paid"} color="green" SvgComponent={Check} />
            )}
          </div>
        </Title>
        <Action>
          <Button onClick={() => setOpen(!open)} open={open}>
            <div>Order Details</div>
            <SvgIcon SvgComponent={ChevronDown} />
          </Button>
          {needsPayment && (
            <Button
              color="red"
              onClick={() => {
                navigate(`/checkout/?orderid=${orderNumber}`)
              }}
            >
              <div>Pay Now</div>
              <SvgIcon SvgComponent={ArrowRight} hue="white" shade="000" />
            </Button>
          )}
        </Action>
      </Summary>
      <Details open={open}>
        <DetailContent>
          <div>
            <LabeledIcon label="Shipping Address" SvgComponent={LocationPin} />
            <p>
              {billing.first_name} {billing.last_name}
            </p>
            <p>{billing.address_1}</p>
            <p>{billing.address_2}</p>
            <p>{billing.city}</p>
            <p>{billing.state}</p>
            <p>{billing.postcode}</p>
            <p>{billing.phone}</p>
          </div>
          <div className="order-summary">
            {!shipping_method ? null : (
              <>
                <LabeledIcon label="Delivery Info" SvgComponent={Delivery} />
                <p>Method: {shipping_method}</p>
                {!shipping_branch ? null : <p>Branch: {shipping_branch}</p>}
                {!shipping_message ? null : <p>{shipping_message}</p>}
              </>
            )}
            {payment_method && (
              <>
                <LabeledIcon
                  label="Payment Method"
                  SvgComponent={SecurePayment}
                />
                <p>{payment_method}</p>
              </>
            )}
          </div>
          <div className="order-summary">
            <LabeledIcon label="Order Summary" SvgComponent={AddressCard} />
            <p>
              Total Items: <span>{count}</span>
            </p>
            {discount > 0 ? (
              <p>
                Discount: <span>{formatMoney(discount)}</span>
              </p>
            ) : null}
            <p>
              Delivery Cost: <span>{formatMoney(shipping_total)}</span>
            </p>
            <p>
              Order Total: <span>{formatMoney(total)}</span>
            </p>
          </div>
        </DetailContent>
      </Details>
      <div>
        {items.map((props) => (
          <Item
            {...props}
            key={props.id}
            setCount={setCount}
            urlsAvailable={urlsAvailable}
          />
        ))}
      </div>
    </Container>
  )
}
// ======================
// 	      STYLES
// ======================
const Button = styled.button`
  background: ${(props) => (props.color === "red" ? "#ed1c24" : "#dee3e8")};
  color: ${(props) => (props.color === "red" ? "#ffffff" : "#223247")};
  overflow: hidden;
  border: none;
  border-radius: 3px;
  overflow: hidd0en;
  display: flex;
  align-items: center;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
  div {
    padding: 4px 14px;
    font-size: 16px;
  }
  span.icon {
    background-color: ${(props) =>
      props.color === "red" ? "#d42b31" : "#cfd5db"};
    padding: 7px;
    svg {
      transition: 0.2s all ease-in-out;
      transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
    }
  }
`

const Container = styled.div`
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 4px;
  margin-bottom: 40px;
  background: white;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-bottom: 60px;
  }
`
const Summary = styled.div`
  padding: 20px;
  border-bottom: 2px dashed #e6e6e6;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    > div {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:nth-child(2) {
        flex-direction: column-reverse;
      }

      button {
        width: 100%;
        justify-content: space-between;
        &:nth-child(2) {
          margin-bottom: 10px;
        }
      }
    }
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  h4 {
    font-size: 22px;
    font-weight: 500;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      margin-bottom: 12px;
      text-align: center;
    }
  }
  .badges {
    display: flex;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 20px;
  }
`

const Details = styled.div`
  overflow: hidden;
  max-height: ${({ open }) => (open ? "100vh" : "0")};
  height: 100%;
  transition: all 0.3s ease-in-out;
  border-bottom: ${({ open }) => (open ? "2px dashed #e6e6e6" : "none")};
`
const DetailContent = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
  p {
    font-size: 16px;
    margin-bottom: 6px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 14px;
    }
  }
  .order-summary {
    p {
      justify-content: space-between;
      display: flex;
      margin: 10px 0;
    }
  }
`

export default OrderCard
