import React from "react"

const IconHourglassStart = ({ fill }) => (
  <svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.81 1.63c0 2.5-1.2 5.29-3.54 6.12 2.34.83 3.54 3.61 3.54 6.12h.11c.18 0 .33.15.33.33v.22c0 .18-.15.33-.33.33H1.08a.33.33 0 01-.33-.33v-.22c0-.18.15-.33.33-.33h.1c0-2.5 1.2-5.29 3.55-6.12C2.4 6.92 1.2 4.14 1.2 1.63h-.11a.33.33 0 01-.33-.33v-.22C.75.9.9.75 1.08.75h9.84c.18 0 .33.15.33.33v.22c0 .18-.15.33-.33.33h-.1zm-8.75 0c0 3.02 1.77 5.46 3.94 5.46s3.94-2.44 3.94-5.46H2.06zm7.88 12.24c0-3.02-1.77-5.46-3.94-5.46s-3.94 2.44-3.94 5.46h7.88zM8.56 3.37H3.44a.35.35 0 01-.32-.24 6.57 6.57 0 01-.05-.23c-.05-.2.1-.4.32-.4H8.6c.21 0 .37.2.32.4l-.05.23a.35.35 0 01-.32.25zm-.44 1.4L7.94 5a.33.33 0 01-.25.11H4.3a.33.33 0 01-.25-.11 4.7 4.7 0 01-.18-.24.33.33 0 01.26-.52h3.72c.26 0 .42.3.26.52z"
      fill={fill}
    />
  </svg>
)

export default IconHourglassStart
