import React from "react"
import styled from "styled-components"
import Title from "./Title"
import OrderCard from "./OrderCard"

// ======================
//      COMPONENT
// ======================

const Order = ({
  orderNumber,
  orderNumberFormatted,
  orderDate,
  status,
  items,
  billing,
  payment_method_title,
  date_paid,
  orderTotal,
  shippingTotal,
  discount,
  shippingData,
  urlsAvailable,
}) => {
  return (
    <Container>
      <Title
        orderNumber={orderNumber}
        orderNumberFormatted={orderNumberFormatted}
        orderDate={orderDate}
        payDate={date_paid && date_paid.date}
      />
      <OrderCard
        orderNumber={orderNumber}
        orderNumberFormatted={orderNumberFormatted}
        items={items}
        status={status}
        billing={billing}
        discount={discount}
        payment_method={payment_method_title}
        total={orderTotal}
        shipping_total={shippingTotal}
        shipping_data={shippingData}
        urlsAvailable={urlsAvailable}
      />
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div``

export default Order
